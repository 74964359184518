import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { Container, Typography, TextField, Button, Box, Grid2 as Grid, Snackbar, useMediaQuery, useTheme, } from '@mui/material';
import middlecanLogo from '../../assets/images/middlecan-logo.webp';
var Home = function () {
    var _a = useState(''), email = _a[0], setEmail = _a[1];
    var _b = useState(false), isSubmitted = _b[0], setIsSubmitted = _b[1];
    var theme = useTheme();
    var isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    var handleSubmit = function (e) {
        e.preventDefault();
        console.log('Submitted email:', email);
        setIsSubmitted(true);
    };
    useEffect(function () {
        if (isSubmitted) {
            var timer_1 = setTimeout(function () { return setIsSubmitted(false); }, 3000);
            return function () { return clearTimeout(timer_1); };
        }
    }, [isSubmitted]);
    return (_jsxs(Box, { sx: {
            height: '100vh',
            width: '100%',
            bgcolor: 'background.default', // Changed to use theme background color
            color: 'common.white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
        }, children: [_jsx(Container, { maxWidth: "md", children: _jsxs(Grid, { container: true, direction: "column", spacing: 4, alignItems: "center", children: [_jsx(Grid, { children: _jsx("img", { src: middlecanLogo, alt: "MiddleCan.ai Logo", width: isMobile ? 128 : 256, height: isMobile ? 128 : 256, style: { borderRadius: 30, border: '1px solid black' } }) }), _jsx(Grid, { children: _jsx(Typography, { variant: "h2", component: "h1", align: "center", color: "common.black", children: "MiddleCan.ai" }) }), _jsx(Grid, { size: { xs: 12 }, children: _jsx("form", { onSubmit: handleSubmit, children: _jsxs(Grid, { container: true, spacing: 2, justifyContent: "center", children: [_jsx(Grid, { size: { xs: 12, sm: 'auto' }, children: _jsx(TextField, { type: "email", placeholder: "Enter your email", value: email, onChange: function (e) { return setEmail(e.target.value); }, variant: "outlined", fullWidth: isMobile, required: true, sx: {
                                                    bgcolor: 'common.white',
                                                    borderRadius: 2,
                                                    '& .MuiInputBase-input': {
                                                        color: 'common.black',
                                                    },
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                            borderColor: 'common.black',
                                                            borderWidth: 2,
                                                        },
                                                        '&:hover fieldset': {
                                                            borderColor: 'common.black',
                                                            borderWidth: 2,
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            borderColor: 'common.black',
                                                            borderWidth: 2,
                                                        },
                                                    },
                                                    '& .MuiInputLabel-root': {
                                                        color: 'common.black',
                                                    },
                                                } }) }), _jsx(Grid, { size: { xs: 12, sm: 'auto' }, children: _jsx(Button, { type: "submit", variant: "contained", color: "primary", fullWidth: isMobile, sx: { height: '100%' }, children: "Notify Me" }) })] }) }) })] }) }), _jsx(Snackbar, { open: isSubmitted, autoHideDuration: 3000, onClose: function () { return setIsSubmitted(false); }, message: "Thank you! We'll keep you updated." })] }));
};
export default Home;
