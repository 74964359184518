import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { List, ListItem, ListItemButton, ListItemText, ListItemIcon } from '@mui/material';
import { Person as ProfileIcon, Business as CompanyIcon, Settings as SettingsIcon } from '@mui/icons-material';
import { useLocation, Link } from 'react-router-dom';
var ProfileNav = function () {
    var location = useLocation();
    var navItems = [
        { text: 'Profile', icon: _jsx(ProfileIcon, {}), path: '/profile' },
        { text: 'Company', icon: _jsx(CompanyIcon, {}), path: '/company' },
        { text: 'Settings', icon: _jsx(SettingsIcon, {}), path: '/settings' },
    ];
    return (_jsx(List, { sx: { width: '100%', p: 0 }, children: navItems.map(function (item) { return (_jsx(ListItem, { disablePadding: true, children: _jsxs(ListItemButton, { component: Link, to: item.path, selected: location.pathname === item.path, children: [_jsx(ListItemIcon, { children: item.icon }), _jsx(ListItemText, { primary: item.text })] }) }, item.text)); }) }));
};
export default ProfileNav;
