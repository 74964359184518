var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { signUp } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import { Container, Box, Grid2 as Grid, Typography, TextField, Button, Alert, IconButton } from '@mui/material';
import { Google as GoogleIcon } from '@mui/icons-material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import testimonial from "../../assets/images/testimonial.png";
var Register = function () {
    var _a = useState(''), email = _a[0], setEmail = _a[1];
    var _b = useState(''), password = _b[0], setPassword = _b[1];
    var _c = useState(''), confirmPassword = _c[0], setConfirmPassword = _c[1];
    var _d = useState(false), showPassword = _d[0], setShowPassword = _d[1];
    var _e = useState(false), showConfirmPassword = _e[0], setShowConfirmPassword = _e[1];
    var _f = useState(''), error = _f[0], setError = _f[1];
    var navigate = useNavigate();
    var handleClickShowPassword = function () { return setShowPassword(!showPassword); };
    var handleClickShowConfirmPassword = function () { return setShowConfirmPassword(!showConfirmPassword); };
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, isSignUpComplete, userId, nextStep, err_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    e.preventDefault();
                    setError('');
                    if (password !== confirmPassword) {
                        setError('Passwords do not match');
                        return [2 /*return*/];
                    }
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, signUp({
                            username: email,
                            password: password,
                            options: {
                                userAttributes: {
                                    email: email,
                                },
                            },
                        })];
                case 2:
                    _a = _b.sent(), isSignUpComplete = _a.isSignUpComplete, userId = _a.userId, nextStep = _a.nextStep;
                    if (isSignUpComplete) {
                        console.log('Registration successful. User ID:', userId);
                        navigate('/login');
                    }
                    else {
                        console.log('Additional steps required:', nextStep);
                        navigate('/confirm', { state: { email: email } });
                    }
                    return [3 /*break*/, 4];
                case 3:
                    err_1 = _b.sent();
                    if (err_1 instanceof Error) {
                        setError(err_1.message);
                    }
                    else {
                        setError('An error occurred during registration');
                    }
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (_jsx(Container, { maxWidth: "md", sx: { display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }, children: _jsxs(Box, { sx: {
                display: 'flex',
                flexDirection: 'row',
                gap: 4,
                boxShadow: 3,
                borderRadius: 2,
                p: 3,
                bgcolor: 'background.paper',
            }, children: [_jsxs(Box, { sx: { width: '100%', maxWidth: '400px' }, children: [_jsx(Typography, { variant: "h4", component: "h2", gutterBottom: true, children: "Register Here" }), _jsx(Typography, { variant: "body1", color: "textSecondary", sx: { mb: 2 }, children: "We can assign tasks, set deadlines, and track progress effortlessly." }), _jsx("form", { onSubmit: handleSubmit, children: _jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { size: 12, children: _jsx(TextField, { fullWidth: true, label: "Email", type: "email", value: email, onChange: function (e) { return setEmail(e.target.value); }, required: true, autoComplete: "off" }) }), _jsx(Grid, { size: 12, children: _jsx(TextField, { fullWidth: true, label: "Password", type: showPassword ? 'text' : 'password', value: password, onChange: function (e) { return setPassword(e.target.value); }, required: true, autoComplete: "new-password", slotProps: {
                                                input: {
                                                    endAdornment: (_jsx(IconButton, { onClick: handleClickShowPassword, children: showPassword ? _jsx(VisibilityOff, {}) : _jsx(Visibility, {}) })),
                                                },
                                            } }) }), _jsx(Grid, { size: 12, children: _jsx(TextField, { fullWidth: true, label: "Confirm Password", type: showConfirmPassword ? 'text' : 'password', value: confirmPassword, onChange: function (e) { return setConfirmPassword(e.target.value); }, required: true, autoComplete: "new-password", slotProps: {
                                                input: {
                                                    endAdornment: (_jsx(IconButton, { onClick: handleClickShowConfirmPassword, children: showConfirmPassword ? _jsx(VisibilityOff, {}) : _jsx(Visibility, {}) })),
                                                },
                                            } }) }), _jsx(Grid, { size: 12, children: _jsx(Button, { type: "submit", variant: "contained", color: "primary", fullWidth: true, children: "Sign Up" }) }), _jsx(Grid, { size: 12, children: _jsx(Button, { variant: "outlined", startIcon: _jsx(GoogleIcon, {}), color: "secondary", fullWidth: true, children: "Sign Up with Google" }) })] }) }), error && (_jsx(Alert, { severity: "error", sx: { mt: 2 }, children: error })), _jsxs(Typography, { variant: "body2", sx: { mt: 2 }, children: ["Have an account? ", _jsx("a", { href: "/login", children: "Login Now" })] })] }), _jsx(Box, { sx: {
                        display: { xs: 'none', md: 'block' },
                        width: '100%',
                        maxWidth: '500px',
                        height: 'auto',
                        borderRadius: 2,
                        overflow: 'hidden',
                    }, children: _jsx("img", { src: testimonial, alt: "Testimonial", style: { width: '100%', height: '100%', objectFit: 'cover' } }) })] }) }));
};
export default Register;
