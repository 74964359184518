import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { createTheme, ThemeProvider, Container, Box } from '@mui/material';
import { Authenticator } from '@aws-amplify/ui-react';
import Home from './pages/web/Home';
import Console from './pages/console/Console';
import Profile from './pages/console/Profile';
import Company from './pages/console/Organization';
import Settings from './pages/console/Settings';
import Login from './pages/login/Login';
import Register from './pages/login/Register';
import Confirm from './pages/login/Confirm';
import Reset from './pages/login/Reset';
import SecureRoute from './components/security/SecureRoute';
import { Amplify } from 'aws-amplify';
import amplifyconfig from './amplifyconfiguration.json';
Amplify.configure(amplifyconfig);
var theme = createTheme({
    palette: {
        primary: {
            main: '#000000',
        },
        secondary: {
            main: '#000000',
        },
        background: {
            default: '#f5f5f5', // Light grey background
        },
    },
});
function App() {
    return (_jsx(Authenticator.Provider, { children: _jsx(ThemeProvider, { theme: theme, children: _jsx(Router, { children: _jsx(Box, { sx: { bgcolor: 'background.default', minHeight: '100vh' }, children: _jsx(Container, { maxWidth: "lg", sx: { py: 4 }, children: _jsxs(Routes, { children: [_jsx(Route, { path: "/", element: _jsx(Home, {}) }), _jsx(Route, { path: "/console", element: _jsx(SecureRoute, { children: _jsx(Console, {}) }) }), _jsx(Route, { path: "/profile", element: _jsx(SecureRoute, { children: _jsx(Profile, {}) }) }), _jsx(Route, { path: "/company", element: _jsx(SecureRoute, { children: _jsx(Company, {}) }) }), _jsx(Route, { path: "/settings", element: _jsx(SecureRoute, { children: _jsx(Settings, {}) }) }), _jsx(Route, { path: "/register", element: _jsx(Register, {}) }), _jsx(Route, { path: "/confirm", element: _jsx(Confirm, {}) }), _jsx(Route, { path: "/forgot-password", element: _jsx(Reset, {}) }), _jsx(Route, { path: "/login", element: _jsx(Login, {}) })] }) }) }) }) }) }));
}
export default App;
