var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { confirmSignUp, resendSignUpCode } from 'aws-amplify/auth';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Box, Grid2 as Grid, Typography, TextField, Button, Alert, Link } from '@mui/material';
var Confirm = function () {
    var _a;
    var _b = useState(''), email = _b[0], setEmail = _b[1];
    var _c = useState(''), confirmationCode = _c[0], setConfirmationCode = _c[1];
    var _d = useState(''), error = _d[0], setError = _d[1];
    var _e = useState(''), message = _e[0], setMessage = _e[1];
    var _f = useState(false), isResendForm = _f[0], setIsResendForm = _f[1];
    var navigate = useNavigate();
    var location = useLocation();
    var currentEmail = ((_a = location.state) === null || _a === void 0 ? void 0 : _a.email) || email;
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    setError('');
                    setMessage('');
                    if (!currentEmail) {
                        setError('Please provide an email address');
                        return [2 /*return*/];
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, confirmSignUp({
                            username: currentEmail,
                            confirmationCode: confirmationCode,
                        })];
                case 2:
                    _a.sent();
                    setMessage('Account confirmed successfully!');
                    setTimeout(function () { return navigate('/portal'); }, 2000);
                    return [3 /*break*/, 4];
                case 3:
                    err_1 = _a.sent();
                    setError(err_1 instanceof Error ? err_1.message : 'An error occurred during confirmation');
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleResendCode = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var err_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    setError('');
                    setMessage('');
                    if (!email) {
                        setError('Please provide an email address');
                        return [2 /*return*/];
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, resendSignUpCode({ username: email })];
                case 2:
                    _a.sent();
                    setMessage('Confirmation code resent. Please check your email.');
                    setIsResendForm(false);
                    return [3 /*break*/, 4];
                case 3:
                    err_2 = _a.sent();
                    setError(err_2 instanceof Error ? err_2.message : 'An error occurred while resending the code');
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var toggleForm = function () {
        setIsResendForm(!isResendForm);
        setError('');
        setMessage('');
    };
    return (_jsx(Container, { maxWidth: "sm", sx: { display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }, children: _jsxs(Box, { sx: { width: '100%', maxWidth: '400px', p: 3, boxShadow: 3, borderRadius: 2, bgcolor: 'background.paper' }, children: [_jsx(Typography, { variant: "h4", component: "h2", gutterBottom: true, children: isResendForm ? 'Resend Confirmation Code' : 'Confirm Your Account' }), !isResendForm ? (_jsx("form", { onSubmit: handleSubmit, children: _jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { size: 12, children: _jsx(TextField, { fullWidth: true, label: "Confirmation Code", value: confirmationCode, onChange: function (e) { return setConfirmationCode(e.target.value); }, required: true }) }), _jsx(Grid, { size: 12, children: _jsx(Button, { type: "submit", variant: "contained", color: "primary", fullWidth: true, children: "Confirm" }) })] }) })) : (_jsx("form", { onSubmit: handleResendCode, children: _jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { size: 12, children: _jsx(TextField, { fullWidth: true, label: "Email", type: "email", value: email, onChange: function (e) { return setEmail(e.target.value); }, required: true }) }), _jsx(Grid, { size: 12, children: _jsx(Button, { type: "submit", variant: "contained", color: "primary", fullWidth: true, children: "Resend Code" }) })] }) })), _jsx(Box, { sx: { mt: 2, textAlign: 'center' }, children: _jsx(Link, { component: "button", variant: "body2", onClick: toggleForm, children: isResendForm ? 'Back to Confirmation' : 'Need a new code?' }) }), error && (_jsx(Alert, { severity: "error", sx: { mt: 2 }, children: error })), message && (_jsx(Alert, { severity: "success", sx: { mt: 2 }, children: message }))] }) }));
};
export default Confirm;
