import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Container, Box, Grid2 as Grid, Typography } from '@mui/material';
import ProfileNav from '../../components/navigation/ProfileNav';
var Settings = function () {
    return (_jsx(Container, { maxWidth: "lg", sx: { mt: 4, py: 4 }, children: _jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { size: { xs: 12, md: 3, lg: 3 }, children: _jsx(Box, { sx: { display: 'flex', flexDirection: 'column', gap: 2 }, children: _jsx(Box, { sx: { bgcolor: 'background.paper' }, children: _jsx(ProfileNav, {}) }) }) }), _jsx(Grid, { size: { xs: 12, md: 9, lg: 9 }, children: _jsxs(Box, { sx: {
                            p: 2,
                            borderRadius: 2,
                            bgcolor: 'white',
                        }, children: [_jsx(Typography, { variant: "h6", gutterBottom: true, children: "Settings" }), _jsx(Typography, { variant: "body1", children: "This is the settings page. Content will be added here." })] }) })] }) }));
};
export default Settings;
